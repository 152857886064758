// (function ($) {
//     "use strict";

//     // Preloader
//     $(window).on('load', function () {
//         if ($('#preloader').length) {
//             $('#preloader').delay(200).fadeOut('slow', function () {
//                 $(this).remove();
//             });
//         }
//     });

//     // Back to top button
//     $(window).scroll(function () {
//         if ($(this).scrollTop() > 100) {
//             $('.back-to-top').fadeIn('slow');
//         } else {
//             $('.back-to-top').fadeOut('slow');
//         }
//     });
//     $('.back-to-top').click(function () {
//         $('html, body').animate({ scrollTop: 0 }, 1500, 'easeInOutExpo');
//         return false;
//     });

//     var nav = $('nav');
//     var navHeight = nav.outerHeight();

//     /*--/ ScrollReveal /Easy scroll animations for web and mobile browsers /--*/
//     window.sr = ScrollReveal();
//     sr.reveal('.foo', { duration: 1000, delay: 15 });

//     /*--/ Carousel owl /--*/
//     $('#carousel').owlCarousel({
//         loop: true,
//         margin: -1,
//         items: 1,
//         nav: true,
//         navText: ['<i class="ion-ios-arrow-back" aria-hidden="true"></i>', '<i class="ion-ios-arrow-forward" aria-hidden="true"></i>'],
//         autoplay: true,
//         autoplayTimeout: 5000,
//         autoplayHoverPause: true
//     });

//     /*--/ Animate Carousel /--*/
//     $('.intro-carousel').on('translate.owl.carousel', function () {
//         $('.intro-content .intro-title').removeClass('zoomIn animated').hide();
//         $('.intro-content .intro-price').removeClass('fadeInUp animated').hide();
//         $('.intro-content .intro-title-top, .intro-content .spacial').removeClass('fadeIn animated').hide();
//     });

//     $('.intro-carousel').on('translated.owl.carousel', function () {
//         $('.intro-content .intro-title').addClass('zoomIn animated').show();
//         $('.intro-content .intro-price').addClass('fadeInUp animated').show();
//         $('.intro-content .intro-title-top, .intro-content .spacial').addClass('fadeIn animated').show();
//     });

//     /*--/ Navbar Collapse /--*/
//     $('.navbar-toggle-box-collapse').on('click', function () {
//         $('body').removeClass('box-collapse-closed').addClass('box-collapse-open');
//     });
//     $('.close-box-collapse, .click-closed').on('click', function () {
//         $('body').removeClass('box-collapse-open').addClass('box-collapse-closed');
//         $('.menu-list ul').slideUp(700);
//     });

//     /*--/ Property owl /--*/
//     $('#property-carousel').owlCarousel({
//         loop: true,
//         margin: 30,
//         responsive: {
//             0: {
//                 items: 1,
//             },
//             769: {
//                 items: 2,
//             },
//             992: {
//                 items: 3,
//             }
//         }
//     });

//     /*--/ Property owl owl /--*/
//     $('#property-single-carousel').owlCarousel({
//         loop: true,
//         margin: 0,
//         nav: true,
//         navText: ['<i class="ion-ios-arrow-back" aria-hidden="true"></i>', '<i class="ion-ios-arrow-forward" aria-hidden="true"></i>'],
//         responsive: {
//             0: {
//                 items: 1,
//             }
//         }
//     });

//     /*--/ Testimonials owl /--*/
//     $('#testimonial-carousel').owlCarousel({
//         margin: 0,
//         autoplay: true,
//         nav: true,
//         animateOut: 'fadeOut',
//         animateIn: 'fadeInUp',
//         navText: ['<i class="ion-ios-arrow-back" aria-hidden="true"></i>', '<i class="ion-ios-arrow-forward" aria-hidden="true"></i>'],
//         autoplayTimeout: 4000,
//         autoplayHoverPause: true,
//         responsive: {
//             0: {
//                 items: 1,
//             }
//         }
//     });

// })(jQuery);

// $(function () {
//     $('[data-toggle="tooltip"]').tooltip()
// })

// $(".toggle-password").click(function () {

//     $(this).toggleClass("fa-eye fa-eye-slash");
//     var input = $($(this).attr("toggle"));
//     if (input.attr("type") == "password") {
//         input.attr("type", "text");
//     } else {
//         input.attr("type", "password");
//     }
// });




// card validation
function checkCardNumber() {

    //For Card Number formatted input
    var cardNum = document.getElementById('cr_no');
    cardNum.onkeyup = function (e) {
        if (this.value == this.lastValue) return;
        var caretPosition = this.selectionStart;
        var sanitizedValue = this.value.replace(/[^0-9]/gi, '');
        var parts = [];

        for (var i = 0, len = sanitizedValue.length; i < len; i += 4) { parts.push(sanitizedValue.substring(i, i + 4)); } for (var i = caretPosition - 1; i >= 0; i--) {
            var c = this.value[i];
            if (c < '0' || c > '9') {
                caretPosition--;
            }
        }
        caretPosition += Math.floor(caretPosition / 4);

        this.value = this.lastValue = parts.join(' ');
        this.selectionStart = this.selectionEnd = caretPosition;
    }

    //For Date formatted input
    var expDate = document.getElementById('exp');
    expDate.onkeyup = function (e) {
        if (this.value == this.lastValue) return;
        var caretPosition = this.selectionStart;
        var sanitizedValue = this.value.replace(/[^0-9]/gi, '');
        var parts = [];

        for (var i = 0, len = sanitizedValue.length; i < len; i += 2) { parts.push(sanitizedValue.substring(i, i + 2)); } for (var i = caretPosition - 1; i >= 0; i--) {
            var c = this.value[i];
            if (c < '0' || c > '9') {
                caretPosition--;
            }
        }
        caretPosition += Math.floor(caretPosition / 2);

        this.value = this.lastValue = parts.join('/');
        this.selectionStart = this.selectionEnd = caretPosition;
    }

    var cvv = document.getElementById('cvv');
    cvv.onkeyup = function (e) {
        if (this.value == this.lastValue) return;
        var caretPosition = this.selectionStart;
        var sanitizedValue = this.value.replace(/[^0-9]/gi, '');
        var parts = [];

        for (var i = 0, len = sanitizedValue.length; i < len; i += 3) { parts.push(sanitizedValue.substring(i, i + 3)); } for (var i = caretPosition - 1; i >= 0; i--) {
            var c = this.value[i];
            if (c < '0' || c > '9') {
                caretPosition--;
            }
        }
        caretPosition += Math.floor(caretPosition / 3);

        this.value = this.lastValue = parts.join('');
        this.selectionStart = this.selectionEnd = caretPosition;
    }
}